<template>
  <footer class="footer">
    <modal-show-policy slot="blockTitle" ref="policyModal" :pdf-base64="fileURL"></modal-show-policy>
    <div class="container md-offset">
      <div class="text-center md-layout-item md-size-100">
        <nav>
          <ul>
            <li v-for="link in policies" :key="link.policyId">
              <a style="color: #000 !important" href="javascript:void();" @click="onViewPolicy(link.policyId)">{{
                link.title
              }}</a>
            </li>
          </ul>
        </nav>
        <p style="color: #000">Version : {{ getAppVersion }}</p>
      </div>
    </div>
  </footer>
</template>
<script>
import { mapState } from 'vuex';
import ModalShowPolicy from '@/components/Lims/modals/ModalShowPolicy';

import { ConfigMixins } from '@/core/mixins';

import PolicyService from '@/services/policy.service';

export default {
  mixins: [ConfigMixins],
  components: {
    ModalShowPolicy,
  },

  async created() {
    const { data, error } = await PolicyService.getPoliciesLoginPage();
    if (!error) {
      this.policies = data;
    }
  },

  computed: {
    ...mapState('app', {
      getAppVersion: (state) => state.appVersion,
    }),
  },

  data: function () {
    return {
      policies: null,
      fileURL: null,
    };
  },
  methods: {
    async onViewPolicy(policyId) {
      const res = await PolicyService.downloadFilePolicyLoginPage(policyId);
      this.fileURL = URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
      this.$refs.policyModal.open();
    },
  },
};
</script>
<style></style>
