<template>
  <div class="vld-parent" v-if="isLoading">
    <md-progress-bar class="md-primary" md-mode="indeterminate"></md-progress-bar>
    <!-- <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="fullPage"></loading> -->
  </div>
</template>

<script>
// Import component
// import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  computed: {
    isLoading: {
      get: function () {
        return this.$store.state.app.isLoading;
      },
      set: function (isLoading) {
        this.$store.commit('app/toggleLoading', { isLoading });
        // this.$store.state.app.isLoading = val;
      },
    },
  },
  methods: {},
  data() {
    return {
      fullPage: true,
    };
  },
  components: {
    // Loading,
  },
};
</script>

<style>
.vld-parent {
  position: fixed;
  top: 0;
  width: 100%;
  height: 10px;
  z-index: 1030;
}
</style>
